/*
old_device_id
new_device_id
device_name
properties
serial_num
installation_status
tags
fields
conn_request_payload
*/

enum CSV_COLUMNS {
  old_device_id,
  new_device_id,
  device_name,
  properties,
  serial_num,
  installation_status,
  tags,
  fields,
  conn_request_payload,
}
const BULK_REPLACE_RECORD_LIMIT = 500;
export { CSV_COLUMNS, BULK_REPLACE_RECORD_LIMIT };
