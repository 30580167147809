import React from "react";
import { useSnackbar } from "notistack";
import {
  CustomButton,
  LoadingIndicator,
  SubmitButton,
} from "components/UiComponents";
import useApi from "hooks/useApi";
import validateNativeTags from "components/Devices/DeviceManagement/utils/validateNativeTags";
import useStyles from "./Submit.styles";
import readError from "utils/readError";

const tthex = /^[0-9a-fA-F]{32}$/;
const sthex = /^[0-9a-fA-F]{16}$/;
const letterNumber = /^[0-9a-zA-Z_-\s]*$/;
const letterNumberID = /^[0-9a-zA-Z_-]*$/;

//TODO: Need to move a common utility
const validateConnectionConfiguration = (deviceData: any) => {
  if (
    deviceData.conn_config &&
    deviceData.conn_config?.metadata.config_type === "LORIOT"
  ) {
    const { appeui, appkey, description, devclass, deveui, title } =
      deviceData?.conn_request_payload || {};

    if (title === "") {
      throw new Error("Please enter device title for LORIOT");
    } else if (description === "") {
      throw new Error("Please enter device description for LORIOT");
    } else if (devclass === "") {
      throw new Error("Please select device class");
    } else if (appkey === "" || !tthex.test(appkey)) {
      throw new Error("Please enter valid App Key (32 character HEX)");
    } else if (appeui === "" || !sthex.test(appeui)) {
      throw new Error("Please enter valid App EUI (16 character HEX)");
    } else if (deveui === "" || !sthex.test(deveui)) {
      throw new Error("Please enter valid Device EUI (16 character HEX)");
    } else if (deveui.toUpperCase() !== deveui) {
      throw new Error("Please enter Device EUI in upper case");
    }
  }
};

const validateAlphaNumeric = (value: string) =>
  !value ||
  !letterNumber.test(value) ||
  value.length > 50 ||
  value.trim() === "";

const validateForm = (formValues: any) => {
  if (!formValues.application_id) {
    throw new Error("Please select an Application");
  }
  if (!formValues.device_config) {
    throw new Error("Please select a Configuration");
  }
};

const validateData = (deviceData: any) => {
  if (
    (deviceData.device_id || "").trim() === "" ||
    !letterNumberID.test(deviceData.device_id) ||
    deviceData.device_id.length > 64
  ) {
    throw new Error(
      `Invalid Device ID (only 0-9,A-Z,a-z,_,- allowed upto 64 characters)`
    );
  }

  if (validateAlphaNumeric(deviceData.device_name)) {
    throw new Error(
      `Invalid Device Name (only 0-9,A-Z,a-z,_,- allowed upto 50 characters)`
    );
  }
  if (validateAlphaNumeric(deviceData.serial_num)) {
    throw new Error(
      `Invalid Serial (only 0-9,A-Z,a-z,_,- allowed upto 50 characters)`
    );
  }

  if (!deviceData.cloud_gateway) {
    throw new Error(`Please select IoT Hub`);
  }
  if (!deviceData.application_level_id) {
    throw new Error(`Please select Application Level`);
  }

  //TODO: validateNativeTags needs to throw error, currently it is returning a string if there is an error
  const tagValidationResult = validateNativeTags(deviceData.device_config.tags);

  if (tagValidationResult) {
    throw new Error(tagValidationResult);
  }
};

const mapData = (formValues: any) => {
  let mappedData: any = {
    device: {
      application_level_id: formValues.application_level_id,
      device_id: formValues.device_id,
      device_name: formValues.device_name,
      serial_num: formValues.serial_num,
      device_config_id: formValues?.device_config?.device_config_id,
      device_config: {
        config_name: formValues?.device_config?.config_name,
        device_type: formValues?.device_config?.device_type,
        auth_type: formValues?.device_config?.auth_type,
        edge_type: formValues?.device_config?.edge_type,
        add_to_iot_hub: "y",
        properties: formValues?.device_config?.properties,
        tags: formValues?.device_config?.tags,
      },

      device_install_status_id: formValues.device_install_status_id,
      cloud_gateway: formValues.cloud_gateway,
      fields: formValues.fields,
    },
  };

  if (formValues.conn_config) {
    mappedData = {
      ...mappedData,
      device: {
        ...mappedData.device,
        conn_config: {
          config_name: formValues.conn_config.config_name,
          conn_config_id: formValues.conn_config.conn_config_id,
          metadata: formValues.conn_config.metadata,
        },
        conn_config_id: formValues.conn_config.conn_config_id,
        conn_request_payload: formValues.conn_config.conn_request_payload,
      },
    };
  }
  return mappedData;
};

const Submit: React.FC<any> = ({
  application: applicationId,
  onDone,
  formValues,
  disabled,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { status: saveRequestStatus, trigger: submitData } = useApi(
    `/applications/${applicationId}/devices`,
    {
      method: "POST",
      deferred: true,
      includePMSCredentialsInRequestBody: true,
      responseDataFormatter: (data: any) => {
        if ((data?.message || "").toLowerCase() === "success") {
          enqueueSnackbar("Device creation initiated", { variant: "success" });
          onDone();
        }
      },
      onError: () => {
        enqueueSnackbar("Device creation failed", {
          variant: "error",
        });
      },
      // mock: { fetcher: () => ({ message: "success" }) },
    }
  );

  return (
    <div className={classes.buttonWrapper}>
      {saveRequestStatus.pending && <LoadingIndicator />}
      <CustomButton variant="outlined-white" onClick={onDone}>
        Cancel
      </CustomButton>
      <SubmitButton
        disabled={saveRequestStatus.pending || disabled}
        onClick={() => {
          const data = mapData(formValues);
          try {
            console.log({ formValues });
            validateForm(formValues);
            validateData(data.device);

            validateConnectionConfiguration(data.device);
          } catch (err) {
            const errorMessage = readError(err);
            return enqueueSnackbar(errorMessage, { variant: "error" });
          }

          submitData(data);
        }}
      >
        Save Device
      </SubmitButton>
    </div>
  );
};

export default Submit;
