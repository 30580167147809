import React, { useState, useEffect } from "react";
import { makeStyles, withStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Grid, TextField, FormControlLabel, Checkbox, InputLabel, Button, Typography } from "@material-ui/core";
import { arrayOfErrorsRefresh, arrayOfErrorsLogout } from '../../utils/helper';
import callXhrRequest from '../../utils/xhrRequestHandler';
import { useStore } from "mobx-store-provider";
import { useSnackbar } from "notistack";
import { Link, NavLink } from 'react-router-dom';
import { API } from "../../api/property";
import BreadcumbComponent from '../../utils/breadcurmb';

const colorLiteral = "1px solid #464659"
const CssTextField = withStyles({
  root: {
    "& input": {
      padding: "10px",
      color: "#ffffff",
      backgroundColor: "#1D1D25 ",
      border: `${colorLiteral}`,
      borderRadius: "2px",
      marginTop: "16px",
    },
    "& .MuiInput-underline": {
      "&&:before": {
        borderBottom: "none",
      },
      "&&:after": {
        borderBottom: "none",
      },
    },
    "& label.Mui-focused": {
      color: "#ffffff",
    },
    "& label": {
      //paddingLeft: "10px"
    },
    "&& label1": {
      paddingRight: "10px"
    },
  },
})(TextField);
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      margin: "30px",
    },
    noSpace: {
      padding: "0px",
      margin: "0px",
    },
    spaceTop: {
      marginTop: "35px",
    },
    space2Top: {
      marginTop: "20px",
    },
    spaceRight: {
      paddingRight: "10px",
    },
    spaceLeft: {
      paddingLeft: "10px",
    },
    formTitle: {
      fontWeight: "bold",
      fontSize: "21px",
      lineHeight: "25px",
      color: "#FFFFFF",
    },
    gridRoot: {
      flexGrow: 1,
      padding: '0.5em 24px',
      backgroundColor: "#2B2B36"
    },
    form: {
      width: "100%", // Fix IE 11 issue.
    },
    fieldWrapper: {
      marginTop: "20px",
    },
    labels: {
      color: "#fcfcfc",
      lineHeight: "19px",
      fontSize: "16px",
      transform: "scale(1)",
    },
    accordionRoot: {
      color: "#FFFFFF",
      margin: "0px",
      padding: "0px",
      boxShadow: "none",
      "& .MuiAccordionSummary-content": {
        margin: "0px",
      },
      minHeight: "auto !important",
    },
    noSpaceNoMin: {
      margin: "0px !important",
      padding: "0px !important",
      minHeight: "auto !important",
    },
    typoRoot: {
      paddingLeft: "24px",
    },
    field: {
      color: "#FCFCFC",
      fontSize: "12px",
      padding: "10px",
    },
    textfield: {
      color: "#ffffff",
      backgroundColor: "#1D1D25",
      border: "1px solid #464659",
      borderRadius: "2px",
      boxSizing: "border-box",
    },
    icon: {
      color: "#FFFFFF",
    },
    options: {
      color: "#FCFCFC",
      fontSize: "12px",
      padding: "10px",
      cursor: "pointer",
    },
    fieldRoot: {
      marginTop: "12px",
    },
    selectRoot: {
      padding: "24px",
    },
    datePickerRoot: {
      color: "#FFFFFF",
      border: "1px solid #464659",
      borderRadius: "2px",
      "& .MuiIconButton-root, .MuiPickersDay-day, .MuiPickersCalendarHeader-dayLabel": {
        color: "#FFFFFF",
      },
      "& .MuiPickersDay-daySelected": {
        border: "1px solid #464659",
      },
    },
    bottomSection: {
      justifyContent: "space-between",
      display: "flex",
      borderTop: "1px solid #33333F",
      paddingTop: "20px",
    },
    btnCancel: {
      borderRadius: "2px",
      border: "1px solid rgba(235,235,245,0.35)",
      color: "#0089FF",
      background: "none",
      fontSize: "16px",
      lineHeight: "19px",
      textTransform: "none",
      padding: "9px 20px",
      marginLeft: "-9px"
    },
    btnAddNUser: {
      backgroundColor: "#0089ff",
      borderRadius: "4px",
      border: "none",
      color: "#FFFFFF",
      "&:hover": {
        backgroundColor: "#0089ff",
      },
      fontSize: "16px",
      lineHeight: "19px",
      textTransform: "none",
      padding: "9px 20px",
    },
    vp: {
      backgroundColor: "#2B2B36",
      border: "1px solid #464659",
      borderRadius: "2px",
      color: "#FCFCFC",
      "&:hover": {
        backgroundColor: "#2B2B36",
      },
      fontSize: "14px",
      lineHeight: "16px",
      textTransform: "none",
      padding: "12px 0px",
      marginTop: "34px",
      width: "100%",
    },
    helperText: {
      color: "#d32f2f !important",
      position: "absolute",
      right: 0,
    },
    checkboxRoot: {
      marginTop: '2em',
      marginBottom: '1em'
    },
    checkboxLabel: {
      fontFamily: 'Avenir Next',
      fontSize: '16px',
      lineHeight: '28px',
      color: '#FFFFFF'
    },
    bgColorBlue: {
      color: '#1976d2 !important'
    },

  })
);

export default function CreateNewResource({toggleDisplayAddNewResource}: any) {
  const classes = useStyles();
  const { user } = useStore();
  const { enqueueSnackbar } = useSnackbar();
  const tokenKey = "sso_token";
  const API_KEY = window.SERVER_DATA.REACT_APP_PM_API_KEY;
  interface sObj {
    pm_s_id: string;
  }
  interface tObj {
    pm_s_token: string;
  }
  let pmSId: sObj = {
    pm_s_id: "",
  };
  let pmST: tObj = {
    pm_s_token: "",
  };
  pmSId =
    sessionStorage.getItem("pm_s_id") || JSON.parse(JSON.stringify(pmSId));
  pmST =
    sessionStorage.getItem("pm_s_token") || JSON.parse(JSON.stringify(pmST));
  const accessToken = JSON.parse(sessionStorage.getItem(tokenKey) as string);
  const [region, setRegion] = useState([] as any);
  const [loader, setLoader] = useState(false);
  const handleChangeRegion = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRegion(event.target.value);
  };
  const [sku, setSKU] = useState([] as any);
  const handleChangeSKU = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSKU(event.target.value);
  };
  const [resourceInputAppl, setResourceInputAppl] = useState(
    [] as any
  );
  const [app, setApp] = useState(
    resourceInputAppl.length > 0 && resourceInputAppl[0].application_name
  );

  const [checkDPS, setCheckDPS] = React.useState(false as any);
  const onDPSCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckDPS(!checkDPS);
  };

  const [checkAutoScale, setCheckAutoScale] = React.useState(false as any);
  const onAutoScale = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckAutoScale(!checkAutoScale);
  };
  const onChangeApplication = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setApp(event.target.value);
  };
  const [subscriptioId, setSubscriptioId] = useState("");
  const [subscriptioName, setSubscriptioName] = useState("");
  const [resourceInput, setResourceInput] = useState([] as any);
  const [skuCapacity, setSkuCapacity] = useState("");
  const [partitionCount, setPartitionCount] = useState("");

  const Regions = [] as any;
  const Skus = [] as any;
  Array.isArray(resourceInput) && resourceInput.forEach((r: any) => {
    if (r.regions) {
      r.regions.forEach((region: any) => {
        Regions.push({ label: region, value: region })
      })
    }
    if (r.skus) {
      r.skus.forEach((s: any) => {
        Skus.push({ label: s, value: s })
      })
    }
  })
  const onCancel = () => {
    setApp("")
    setSKU([]);
    setRegion([]);
    setSubscriptioId("")
    setSubscriptioName("");
    setSkuCapacity("");
    setCheckDPS(false)
    setPartitionCount("");
    toggleDisplayAddNewResource();

  }
  const saveResourceAction = () => {
    setLoader(true);
    const token = JSON.parse(sessionStorage.getItem(tokenKey) as string);
    const CREATE_RESOURCE = `${API["GETALLAPPLS"]}/${app}/az-iot-hubs`;
    const requestOptions = {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token.access_token}`,
        'Ocp-Apim-Subscription-Key': `${API_KEY}`,
        'Ocp-Apim-Trace': `true`
      },
      body: JSON.stringify(
        {
          az_iot_hub: {
            subscription_id: subscriptioId,
            subscription_name: subscriptioName,
            region: region,
            sku: sku,
            sku_capacity: Number(skuCapacity),
            partition_count: Number(partitionCount),
            auto_scale_flag: checkAutoScale,
            iot_hub_type: "new",
            is_dps: checkDPS
          },
          pm_s_token: pmST,
          pm_s_id: pmSId,
        })
    };
    fetch(CREATE_RESOURCE, requestOptions)
      .then(response => response.json())
      .then((response: any) => {
        if ((response && response.status === 401) && (response && arrayOfErrorsRefresh.includes(response.message.toLowerCase()))) {
          try {
            const tokenKey = "sso_token";
            callXhrRequest()
              .then(function (data) {
                sessionStorage.setItem(tokenKey, data);
                saveResourceAction();
              })
              .catch(function (error) {
                user.triggerLogout();
                console.log("error", error);
              });
          } catch (error) {
            console.log(error);
          }
          return;
        } else if ((response && response.status === 401) && (response && arrayOfErrorsLogout.includes(response.message.toLowerCase()))) {
          try {
            user.triggerLogout();
          } catch (error) {
            console.log(error);
          }
          return;
        } else if (response.status === 200 || response.status === 201) {
          enqueueSnackbar('New IoT Hub request initiated.', { variant: "success" });
          setTimeout(() => {
            setLoader(false);
            onCancel();
          }, 500)
        }  else if (response.status === 500 || response.status === 403) {
          enqueueSnackbar(response?.message, { variant: "error" });
          setTimeout(() => {
            setLoader(false);
          }, 500)
        }
      })
      .catch(function (error) {
        enqueueSnackbar('IoT Hub request failed.', { variant: "error" });
        setLoader(false);
        console.log(error);
      });
  }
  const validateField = () => {
    const regexCheck = /^[0-9a-zA-Z_-]+$/;
    const regexNumber = /^[0-9]+$/;
    const subscriptionCheck = /^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/;
    if (!app) {
      enqueueSnackbar("Please select application name.", { variant: "error" });
    } else if (!subscriptioId) {
      enqueueSnackbar("Please enter subscrption id.", { variant: "error" });
    } else if (!(subscriptionCheck.test(subscriptioId))) {
      enqueueSnackbar('Invalid subscription id.', { variant: "error" });
    } else if (!(regexCheck.test(subscriptioId) && subscriptioId.length < 100)) {
      enqueueSnackbar('Invalid subscription id (only 0-9,A-Z,a-z,_,- allowed upto 100 characters).', { variant: "error" });
    } else if (!subscriptioName) {
      enqueueSnackbar("Please enter subscrption name.", { variant: "error" });
    } else if (!(regexCheck.test(subscriptioName) && subscriptioName.length < 100)) {
      enqueueSnackbar('Invalid subscription name (only 0-9,A-Z,a-z,_,- allowed upto 100 characters).', { variant: "error" });
    } else if (region.length === 0) {
      enqueueSnackbar("Please select region.", { variant: "error" });
    } else if (sku.length === 0) {
      enqueueSnackbar("Please select sku.", { variant: "error" });
    } else if (!skuCapacity) {
      enqueueSnackbar("Please enter sku capacity.", { variant: "error" });
    }else if (!(regexNumber.test(skuCapacity) && Number(skuCapacity) >= 1 && Number(skuCapacity) <= (sku !== 'S3_STANDARD'?200:10))) {
      enqueueSnackbar(`Invalid sku capacity (only 1-${sku !== 'S3_STANDARD'?'200':'10'} allowed).`, { variant: "error" });
    } else if (!partitionCount) {
      enqueueSnackbar("Please enter partition count.", { variant: "error" });
    } else if (!(regexNumber.test(partitionCount) && Number(partitionCount) >= 2  && Number(partitionCount) <= 32)) {
      enqueueSnackbar('Invalid partition count (only 2-32 allowed).', { variant: "error" });
    } else {
      saveResourceAction();
    }
  }

  const onLoadApiCall = () => {
    interface sessionObj {
      pm_s_id: string;
    }
    interface tokenObj {
      pm_s_token: string;
    }
    let pmSessionId: sessionObj = {
      pm_s_id: "",
    };
    let pmSessionToken: tokenObj = {
      pm_s_token: "",
    };
    pmSessionId =
      sessionStorage.getItem("pm_s_id") ||
      JSON.parse(JSON.stringify(pmSessionId));
    pmSessionToken =
      sessionStorage.getItem("pm_s_token") ||
      JSON.parse(JSON.stringify(pmSessionToken));

    const RESOURCE_INPUT = API["RESOURCE_INPUT"] + `?pm_s_token=${pmSessionToken}&pm_s_id=${pmSessionId}`;
    fetch(RESOURCE_INPUT, {
      headers: {
        'Authorization': `Bearer ${accessToken.access_token}`,
        'Ocp-Apim-Subscription-Key': `${API_KEY}`,
        'Ocp-Apim-Trace': `true`
      },
    })
      .then((resp) => resp.json())
      .then(function (response) {
        if ((response && response.status === 401) && (response && arrayOfErrorsRefresh.includes(response.message.toLowerCase()))) {
          const tokenKey = "sso_token";
          callXhrRequest()
            .then(function (data) {
              sessionStorage.setItem(tokenKey, data);
              onLoadApiCall()
            })
            .catch(function (error) {
              user.triggerLogout();
              console.log("error", error);
            });
          return;
        }
        else if ((response && response.status === 401) && (response && arrayOfErrorsLogout.includes(response.message.toLowerCase()))) {
          try {
            user.triggerLogout();
          } catch (error) {
            console.log(error);
          }
          return;
        }else{
          setResourceInput(response && response.az_iot_hubs_input);
          setResourceInputAppl(response && response.az_iot_hubs_input[0].applications)
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    onLoadApiCall();
  }, [accessToken.access_token, API_KEY]);

  return (
    <div className={classes.root} style={{ marginTop: "0px" }}>
      <div className={classes.gridRoot} style={{ marginRight: '1.5em', marginBottom: '1.5em', width: '100%', marginLeft: "-10px" }}>
        <Grid container direction='row' xs={12} sm={6} style={{ marginTop: '0.1em', marginBottom: '0.1em' }} alignItems='center'>
          <BreadcumbComponent route='createAzureResources' func={onCancel}/>
        </Grid>
      </div>

      <form className={classes.form} noValidate method="post">
        <div className={classes.space2Top} style={{ marginLeft: '-10px' }}>

          <Grid container>
            <Grid
              item
              xs={6}
              className={classes.spaceRight}
            >
              <div className={classes.fieldWrapper}>
                <InputLabel
                  classes={{ root: classes.labels }}
                  htmlFor="selectApplication"
                >
                  Application *
                </InputLabel>
                <TextField
                  className={classes.textfield}
                  fullWidth
                  select
                  variant="filled"
                  classes={{ root: classes.fieldRoot }}
                  inputProps={{ className: classes.field }}
                  value={app}
                  id="selectApplication"
                  SelectProps={{
                    classes: {
                      iconOutlined: classes.icon,
                    },
                  }}
                  onChange={onChangeApplication}
                >
                  {Array.isArray(resourceInputAppl) && resourceInputAppl.map((option: any) => (
                    <option className={classes.options} value={option.application_id}>
                      {option.application_name}
                    </option>
                  ))}
                </TextField>
              </div>
            </Grid>

            <Grid item xs={6} className={classes.spaceRight}>
              <CssTextField
                fullWidth
                name="subscription_id"
                label="Subscription ID *"
                type="text"
                id="s_id"
                className={classes.fieldWrapper}
                InputProps={{
                  disableUnderline: true,
                  onChange: (e) => setSubscriptioId(e.target.value),
                }}
                InputLabelProps={{
                  shrink: true,
                  className: classes.labels,
                }}
                FormHelperTextProps={{
                  className: classes.helperText,
                }}
              />
            </Grid>

            <Grid item xs={6} className={classes.spaceRight}>
              <CssTextField
                fullWidth
                name="subscription_name"
                label="Subscription Name *"
                type="text"
                id="sub_name"
                className={classes.fieldWrapper}
                InputProps={{
                  disableUnderline: true,
                  onChange: (e) => setSubscriptioName(e.target.value),
                }}
                InputLabelProps={{
                  shrink: true,
                  className: classes.labels,
                }}
                FormHelperTextProps={{
                  className: classes.helperText,
                }}
              />
            </Grid>

            <Grid item xs={6} className={classes.spaceRight}>
              <div className={classes.fieldWrapper}>
                <InputLabel
                  classes={{ root: classes.labels }}
                  htmlFor="selectRole"
                >
                  Region *
                </InputLabel>
                <TextField
                  className={classes.textfield}
                  fullWidth
                  select
                  variant="filled"
                  classes={{ root: classes.fieldRoot }}
                  inputProps={{ className: classes.field }}
                  value={region}
                  id="selectRole"
                  SelectProps={{
                    classes: {
                      iconOutlined: classes.icon,
                    },
                  }}
                  onChange={handleChangeRegion}
                >
                  {Array.isArray(Regions) && Regions.map((option: any) => (
                    <option
                      className={classes.options}
                      value={option.label}
                    >
                      {option.label}
                    </option>
                  ))}
                </TextField>
              </div>
            </Grid>
            <Grid item xs={6} className={classes.spaceRight}>
              <div className={classes.fieldWrapper}>
                <InputLabel classes={{ root: classes.labels }} htmlFor="sku">
                  SKU *
                </InputLabel>
                <TextField
                  className={classes.textfield}
                  fullWidth
                  select
                  variant="filled"
                  classes={{ root: classes.fieldRoot }}
                  inputProps={{ className: classes.field }}
                  value={sku}
                  id="sku"
                  SelectProps={{
                    classes: {
                      iconOutlined: classes.icon,
                    },
                  }}
                  onChange={handleChangeSKU}
                >
                  {Array.isArray(Skus) && Skus.map((option) => (
                    <option
                      className={classes.options}
                      value={option.label}
                    >
                      {option.label}
                    </option>
                  ))}
                </TextField>
              </div>
            </Grid>

            <Grid item xs={6} className={classes.spaceRight}>
              <CssTextField
                fullWidth
                name="sku_capacity"
                label="SKU Capacity *"
                type="text"
                id="sku_capacity"
                className={classes.fieldWrapper}
                InputProps={{
                  disableUnderline: true,
                  onChange: (e) => setSkuCapacity(e.target.value),
                }}
                InputLabelProps={{
                  shrink: true,
                  className: classes.labels,
                }}
                FormHelperTextProps={{
                  className: classes.helperText,
                }}
              />
            </Grid>

            <Grid item xs={6} className={classes.spaceRight}>
              <CssTextField
                fullWidth
                name="partition_count"
                label="Partition Count *"
                type="text"
                id="partition_count"
                className={classes.fieldWrapper}
                InputProps={{
                  disableUnderline: true,
                  onChange: (e) => setPartitionCount(e.target.value),
                }}
                InputLabelProps={{
                  shrink: true,
                  className: classes.labels,
                }}
                FormHelperTextProps={{
                  className: classes.helperText,
                }}
              />
            </Grid>

            <Grid item xs={12} className={classes.spaceRight} >
              <FormControlLabel
                classes={{
                  root: classes.checkboxRoot,
                  label: classes.checkboxLabel
                }}
                control={
                  <Checkbox
                    checked={checkDPS}
                    onChange={onDPSCheck}
                    name="checkDPS"
                    color="primary"
                    className={classes.bgColorBlue}
                  />
                }
                label="Is DPS"
              />
              {/* <FormControlLabel
                classes={{
                  root: classes.checkboxRoot,
                  label: classes.checkboxLabel
                }}
                control={
                  <Checkbox
                    checked={checkAutoScale}
                    onChange={onAutoScale}
                    name="checkAutoScale"
                    color="primary"
                    className={classes.bgColorBlue}
                  />
                }
                label="Auto Scale"
              /> */}
            </Grid>

          </Grid>
        </div>
        <div className={`${classes.space2Top} ${classes.bottomSection}`}>
          <Button
            variant="outlined"
            className={classes.btnCancel}
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            className={classes.btnAddNUser}
            onClick={validateField}
            // style={{ marginRight: '10px' }}
            style={{
              marginRight: '10px',
              pointerEvents: loader ? "none" : "visible",
              cursor: loader ? "default" : "pointer",
              opacity: loader ? ".5" : "1",
            }}
          >
            Save Azure IoT Hub
          </Button>
        </div>
      </form>
    </div>
  );
}
