import React, { useState } from "react";

import { CustomButton, Modal, LoadingIndicator } from "components/UiComponents";
import useApi from "hooks/useApi";
import { useApplicationContext } from "contexts/ApplicationContext";
// import { mockApi } from "./developerTools";
import BulkOperationStatus from "./BulkOperationStatus";
import { useSnackbar } from "notistack";

/*Note: We are passing the application_id from context. So this will 
always come from the outer application selector, which is not visible
in ManageDevicePage. Status check is done per user.
*/
interface IBulkOperationStatusCheckerProps {
  operationType: "BULK_REPLACE" | "BULK_UPDATE";
}

const BulkOperationStatusChecker: React.FC<
  IBulkOperationStatusCheckerProps
> = ({ operationType }) => {
  const { selectedApplication } = useApplicationContext();
  const [showStatusModal, setShowStatusModal] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const {
    data: bulkOperationStatus,
    status,
    trigger: checkBulkOperationStatus,
  } = useApi(`/applications/${selectedApplication.application_id}/bulk-op`, {
    method: "GET",
    deferred: true,
    data: {
      operation_type:
        operationType === "BULK_REPLACE" ? "DEVICE_REPLACE" : "DEVICE_EDIT",
    },
    responseDataFormatter: (data) => {
      setShowStatusModal(true);
      return data;
    },
    onError: () => {
      enqueueSnackbar("An error occurred while reading response", {
        variant: "error",
      });
    },
    // mock: { fetcher: mockApi },
  });

  return (
    <>
      {status.pending && <LoadingIndicator />}
      {bulkOperationStatus && showStatusModal && (
        <Modal
          title={`Bulk ${
            operationType === "BULK_REPLACE" ? "Replace" : "Update"
          } Status`}
          open={true}
          onClose={() => setShowStatusModal(false)}
        >
          <BulkOperationStatus
            bulkOperationStatus={bulkOperationStatus}
            operationType={operationType}
          />
        </Modal>
      )}
      <CustomButton
        variant="outlined-blue"
        onClick={() => checkBulkOperationStatus()}
        style={{ margin: "0" }}
      >
        Status Check
      </CustomButton>
    </>
  );
};

export default BulkOperationStatusChecker;
