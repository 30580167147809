import React from "react";
import { Grid, Typography } from "@material-ui/core";

import { GriddedTextField, DropDown } from "components/UiComponents";
import { useInputContext } from "contexts/InputContext";
import { useApplicationContext } from "contexts/ApplicationContext";
import GriddedDropDownField from "components/UiComponents/GriddedDropdownField";

const Tuple: React.FC<any> = ({ label, value }) => {
  return (
    <Grid item style={{ width: "13em" }}>
      <Typography style={{ fontWeight: "bolder", marginBottom: "5px" }}>
        {label}
      </Typography>
      <Typography>{value}</Typography>
    </Grid>
  );
};

const ConfigInformation: React.FC<any> = ({ configDetails }) => {
  return (
    <Grid
      container
      xs={12}
      style={{ margin: "1em 1.5em", background: "#2b2b36", padding: "1em" }}
    >
      <Tuple label="Device Type" value={configDetails.device_type} />
      <Tuple label="Edge Type" value={configDetails.edge_type} />
      <Tuple label="Authentication Type" value={configDetails.auth_type} />
    </Grid>
  );
};

const SubForm: React.FC<any> = ({ deviceDetails, onFieldChange }) => {
  const inputs = useInputContext();

  console.log({ inputs });
  const applicationLevelOptions = (inputs.application_level || []).map(
    ({ level_name: label, level_id: value }: any) => ({ label, value })
  );

  const iotHubOptions = (inputs.iot_hubs || [])
    .filter(({ iot_hub_name }: any) => iot_hub_name)
    .map(({ iot_hub_name }: any) => ({
      value: iot_hub_name,
      label: iot_hub_name,
    }));

  const installationStatusOptions = (inputs.install_status || []).map(
    ({
      device_install_status_id: value,
      device_installation_status: label,
    }: any) => ({ value, label })
  );

  return (
    <>
      <ConfigInformation configDetails={deviceDetails.device_config} />

      <GriddedTextField
        name="device_id"
        label="Device Id *"
        value={deviceDetails.device_id}
        onChange={(value: string) => onFieldChange("device_id", value)}
      />
      <GriddedTextField
        name="device_name"
        label="Device Name *"
        value={deviceDetails.device_name}
        onChange={(value: string) => onFieldChange("device_name", value)}
      />
      <GriddedTextField
        name="serial_num"
        label="Serial Number *"
        value={deviceDetails.serial_num}
        onChange={(value: string) => onFieldChange("serial_num", value)}
      />

      <GriddedDropDownField
        label="IoT Hub *"
        value={deviceDetails.cloud_gateway}
        options={iotHubOptions}
        onChange={(value: string) => onFieldChange("cloud_gateway", value)}
      />
      <GriddedDropDownField
        label="Application Level *"
        options={applicationLevelOptions}
        value={deviceDetails.application_level_id}
        onChange={(value: string) =>
          onFieldChange("application_level_id", value)
        }
      />

      {installationStatusOptions && (
        <GriddedDropDownField
          label="Installation Status"
          options={installationStatusOptions}
          value={deviceDetails.device_install_status_id}
          onChange={(value: string) =>
            onFieldChange("device_install_status_id", value)
          }
        />
      )}
    </>
  );
};

const BasicDeviceFields: React.FC<any> = ({ deviceDetails, onFieldChange }) => {
  const inputs = useInputContext();
  const { applications } = useApplicationContext();

  const deviceConfigOptions = (inputs.device_configs || []).map(
    ({ device_config_id: value, config_name: label }: any) => ({
      value,
      label,
    })
  );

  return (
    <>
      <Grid item xs={6}>
        <DropDown
          label="Application *"
          options={(applications || []).map(
            ({ application_id: value, application_name: label }) => ({
              value,
              label,
            })
          )}
          value={deviceDetails.application_id}
          onChange={(value: string) => onFieldChange("application_id", value)}
        />
      </Grid>

      <Grid item xs={6}>
        <DropDown
          label="Device Configuration *"
          options={deviceConfigOptions}
          value={deviceDetails?.deviceConfig?.device_config_id}
          onChange={(value: string) => {
            const selectedDeviceConfig = (inputs.device_configs || []).find(
              (dc: any) => dc.device_config_id === value
            );
            onFieldChange("device_config", selectedDeviceConfig);
          }}
        />
      </Grid>
      {deviceDetails?.device_config && (
        <SubForm {...{ deviceDetails, onFieldChange }} />
      )}
    </>
  );
};

export default BasicDeviceFields;
