import React from "react";
import { Grid } from "@material-ui/core";

import { DropDown, IDropDownProps } from "./DropDown";

interface IGriddedDropDownProps extends IDropDownProps {
  fullWidth?: boolean;
}

const GriddedDropDownField: React.FC<IGriddedDropDownProps> = ({
  fullWidth,
  ...props
}) => {
  return (
    <Grid item xs={fullWidth ? 12 : 6}>
      <DropDown {...props} />
    </Grid>
  );
};

export default GriddedDropDownField;
