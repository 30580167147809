import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";

import { useSnackbar } from "notistack";
import {
  CustomButton,
  DropDown,
  SubmitButton,
  LinearProgressBar,
} from "components/UiComponents";
import useApi from "hooks/useApi";
import { InputContextProvider } from "contexts/InputContext";
import { useApplicationContext } from "contexts/ApplicationContext";
import useStyles from "./BulkReplace.styles";
import {
  fileReader,
  sanitizeFileData,
  validateData,
  extractData,
  readError,
} from "./utils";
import {
  FileUploader,
  BulkOperationStatusChecker,
  SampleCSVDownloader,
} from "components/UiComponents";

const BulkReplace: React.FC<any> = ({ returnHome }) => {
  const classes = useStyles();
  const { applications } = useApplicationContext();
  const [selectedApplicationId, setSelectedApplicationId] = useState<any>("");
  const [selectedFile, setSelectedFile] = useState<any>({});
  const [deviceRecords, setDeviceRecords] = useState<any>([]);

  const resetForm = () => {
    setSelectedApplicationId("");
    setSelectedFile({});
    setDeviceRecords([]);
  };

  const { enqueueSnackbar } = useSnackbar();
  const { trigger: bullReplace, status } = useApi(
    `/applications/${selectedApplicationId}/devices/bulk-replace`,
    {
      method: "POST",
      deferred: true,
      includePMSCredentialsInRequestBody: true,
      responseDataFormatter: (responseData: any) => {
        try {
          const {
            data = "An error occurred while processing request",
            message = "error",
          } = responseData || {};
          const variant = message.toLowerCase();
          if (data) {
            enqueueSnackbar(data, {
              variant,
            });
          }
          if (variant === "success") {
            resetForm();
          }
        } catch {
          enqueueSnackbar("An error occurred while processing request", {
            variant: "error",
          });
        }
      },
      //   mock: {
      //     fetcher: (data: any) => {
      //       console.log({ data });
      //     },
      //   },
    }
  );

  const onUpload = (files: any) => {
    fileReader(files[0], (fileData: any) => {
      try {
        const sanitizedData = sanitizeFileData(fileData);
        validateData(sanitizedData);
        const deviceData = extractData(sanitizedData);
        setDeviceRecords(deviceData);
        setSelectedFile(files[0]);
        enqueueSnackbar(`File successfully selected.`, {
          variant: "success",
        });
      } catch (error) {
        const errorMessage = readError(error);
        enqueueSnackbar(errorMessage, { variant: "error" });
      }
    });
  };

  return (
    <InputContextProvider applicationId={selectedApplicationId}>
      <LinearProgressBar show={status.pending} />
      <Grid className={classes.wrapper}>
        <Grid container>
          <Grid item xs={8}>
            <FileUploader
              onError={(error: string) =>
                enqueueSnackbar(error, {
                  variant: "error",
                })
              }
              onUpload={onUpload}
              selectedFile={selectedFile}
              onRemove={() => {
                setSelectedFile({});
                setDeviceRecords([]);
              }}
            />
          </Grid>
          <Grid
            container
            xs={4}
            direction="column"
            alignItems="flex-end"
            justifyContent="space-between"
            style={{ padding: "8px 16px 12px 0" }}
          >
            <SampleCSVDownloader
              href="./Bulk_Replace.csv"
              fileName="Bulk_Replace.csv"
            />
            <BulkOperationStatusChecker operationType="BULK_REPLACE" />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6}>
            {applications && applications.length > 0 && (
              <DropDown
                label="Application *"
                options={applications.map(
                  ({
                    application_id: value,
                    application_name: label,
                  }: any) => ({ value, label })
                )}
                value={selectedApplicationId}
                onChange={(value: string) => setSelectedApplicationId(value)}
              />
            )}
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="space-between"
          className={classes.buttonHolder}
        >
          <CustomButton variant="outlined-blue" onClick={() => returnHome()}>
            Cancel
          </CustomButton>

          <SubmitButton
            disabled={
              deviceRecords.length < 1 ||
              !selectedApplicationId ||
              status.pending
            }
            onClick={() => {
              bullReplace({
                devices: deviceRecords,
              });
            }}
            style={{ height: "100%" }}
          >
            Replace
          </SubmitButton>
        </Grid>
      </Grid>
    </InputContextProvider>
  );
};

export default BulkReplace;
