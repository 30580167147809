import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import { MenuItem } from "@material-ui/core";

const borderCss = "1px solid #464659";
const borderBox = "border-box";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: "flex",
      flexFlow: "column",
      margin: "15px 20px",
      minWidth: "10em",
      "& .Mui-disabled": {
        background: "#2b2b36",
        "& .MuiSelect-root": {
          border: "1px solid #464659",
        },
        "& .MuiSvgIcon-root": {
          fill: "rgb(95, 95, 101)",
        },
      },
    },
    labels: {
      color: "#fcfcfc",
      lineHeight: "16px",
      fontSize: "14px",
      transform: "scale(1)",
    },
    textfield: {
      color: "#ffffff",
      backgroundColor: "#1D1D25",
      border: borderCss,
      borderRadius: "2px",
      boxSizing: borderBox,
    },
    fieldRoot: {
      marginTop: "16px",
    },
    field: {
      color: "#FCFCFC",
      fontSize: "14px",
      lineHeight: "16px",
      padding: "10px",
    },
    icon: {
      color: "#FFFFFF",
    },
    options: {
      color: "#FCFCFC",
      fontSize: "12px",
      padding: "10px",
      cursor: "pointer",
    },
  })
);

interface IDropDownOption {
  value: string;
  label: string;
}

export interface IDropDownProps {
  label?: string;
  options: Array<IDropDownOption>;
  value: string;
  onChange: (value: string) => void;
  style?: React.CSSProperties;
  disabled?: boolean;
}

const DropDown: React.FC<IDropDownProps> = ({
  label,
  options,
  value,
  onChange,
  disabled,
  style = {},
}) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper} style={style}>
      {label && (
        <InputLabel
          classes={{ root: classes.labels }}
          htmlFor="selectApplication"
        >
          {label}
        </InputLabel>
      )}
      <TextField
        className={classes.textfield}
        fullWidth
        select
        variant="outlined"
        classes={{ root: classes.fieldRoot }}
        inputProps={{ className: classes.field }}
        value={value}
        id={`select-${label}`}
        disabled={disabled}
        SelectProps={{
          classes: {
            iconOutlined: classes.icon,
          },
        }}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          onChange(event.target.value)
        }
      >
        {options.map(({ value, label }) => (
          <MenuItem className={classes.options} key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </TextField>
    </div>
  );
};

export default DropDown;
