import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import { Checkbox } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: { display: "flex", alignItems: "center" },
    bgColorBlue: {
      color: "#1976d2 !important",
    },
    bgColorGrey: {
      color: "#4b4b4c !important",
    },
    labels: {
      color: "#fcfcfc",
      lineHeight: "16px",
      fontSize: "14px",
      transform: "scale(1)",
    },
  })
);

interface ICustomCheckboxProps {
  name?: string;
  label: string;
  checked: boolean;
  onChange?: (value: boolean) => void;
  readOnly?: boolean;
  style?: any;
  labelStyle?: any;
}

const CustomCheckbox: React.FC<ICustomCheckboxProps> = ({
  name,
  label,
  checked,
  onChange,
  readOnly = false,
  style = {},
  labelStyle = {},
}) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper} style={style}>
      <Checkbox
        name={name}
        className={readOnly ? classes.bgColorGrey : classes.bgColorBlue}
        readOnly={readOnly}
        checked={checked}
        onChange={() => !readOnly && onChange && onChange(!checked)}
        style={{ padding: "0px 5px" }}
      />
      <div
        className={classes.labels}
        style={{ ...labelStyle, ...(readOnly ? { color: "#5f5f65" } : {}) }}
      >
        {label}
      </div>
    </div>
  );
};

export default CustomCheckbox;
