import React from "react";
import Grid from "@material-ui/core/Grid";

import ClearIcon from "@material-ui/icons/Clear";
import Typography from "@material-ui/core/Typography";
import Dropzone from "react-dropzone";
import AddIcon from "@material-ui/icons/Add";
import DocumentIcon from "@material-ui/icons/PostAdd";
import { CustomButton } from "components/UiComponents";

import useStyles from "./FileUploader.styles";

const FileUploader: React.FC<any> = ({
  onError,
  onUpload,
  selectedFile,
  onRemove,
  extensionAllowed = ".csv",
}) => {
  const classes = useStyles();
  return (
    <Grid item className={classes.draganddrop}>
      <Dropzone
        maxFiles={1}
        multiple={false}
        onDrop={(acceptedFiles) => {
          if (!acceptedFiles[0].name.toLowerCase().endsWith(extensionAllowed)) {
            onError(`Only ${extensionAllowed} file is allowed.`);
          } else {
            onUpload(acceptedFiles);
          }
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <Grid container direction="column">
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justify="space-between"
                >
                  <Grid
                    style={{ display: "flex" }}
                    direction="column"
                    justify="center"
                    alignItems="center"
                  >
                    <DocumentIcon fontSize="large" color="secondary" />
                    <Typography
                      color="secondary"
                      className={classes.uploadText}
                    >
                      DRAG AND DROP A CSV FILE
                    </Typography>
                  </Grid>

                  <Typography color="secondary">or</Typography>

                  <Grid direction="column" justify="center" alignItems="center">
                    <Grid className={classes.dropElement}>
                      <CustomButton
                        variant="outlined-blue"
                        onClick={() => true}
                      >
                        Select a file to upload <AddIcon />
                      </CustomButton>
                    </Grid>
                  </Grid>
                </Grid>
                {selectedFile.name && (
                  <Grid item>
                    <CustomButton
                      variant="outlined-white"
                      onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        onRemove();
                      }}
                    >
                      <Typography color="secondary">
                        {selectedFile.name}
                      </Typography>

                      <ClearIcon></ClearIcon>
                    </CustomButton>
                  </Grid>
                )}
              </Grid>
            </div>
          </section>
        )}
      </Dropzone>
    </Grid>
  );
};

export default FileUploader;
