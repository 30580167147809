import React from "react";
import { CustomButton } from "components/UiComponents";

interface ISampleCSVDownloaderProps {
  href: string;
  fileName: string;
}

const downloadSampleCSV = (href: string, fileName: string) => {
  const link = document.createElement("a");
  link.download = fileName;
  link.href = href;
  link.click();
};

//TODO: Integrate to bulk update
const SampleCSVDownloader: React.FC<ISampleCSVDownloaderProps> = ({
  href,
  fileName,
}) => {
  return (
    <CustomButton
      onClick={() => downloadSampleCSV(href, fileName)}
      variant="outlined-white"
    >
      Download Sample CSV
    </CustomButton>
  );
};

export default SampleCSVDownloader;
