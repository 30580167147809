import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Grid } from "@material-ui/core";

import { DEFAULT_INSTALL_STATUS_VALUE } from "utils/constant";
import { DeviceTagManager } from "components/UiComponents";
import { InputContextProvider } from "contexts/InputContext";
import {
  BasicDeviceFields,
  ConnectionConfiguration,
  Submit,
} from "components/Devices/DeviceManagement/AddDevice/AddThing/formParts";

import { DeviceProperties } from "components/Devices/DeviceManagement/sharedFormParts";
import useStyles from "./AddThing.styles";

const DEFAULT_FORM_VALUES = {
  device_install_status_id: DEFAULT_INSTALL_STATUS_VALUE,
};

const AddDevice: React.FC<any> = () => {
  const history = useHistory();
  const classes = useStyles();
  const [formValues, setFormValues] = useState<any>(DEFAULT_FORM_VALUES);

  const gotoDeviceManagement = () => history.push("/");

  //reset formValues
  useEffect(() => {
    setFormValues({
      ...DEFAULT_FORM_VALUES,
      application_id: formValues.application_id,
    });
  }, [formValues?.application_id]);

  return (
    <InputContextProvider
      applicationId={+formValues.application_id}
      lookup={{ selectedConfigId: formValues.device_config?.device_config_id }}
    >
      <div className={classes.wrapper}>
        <Grid container>
          <BasicDeviceFields
            deviceDetails={formValues}
            onFieldChange={(field: string, value: string) => {
              if (field === "device_id" && formValues?.has_connection_config) {
                setFormValues({
                  ...formValues,
                  device_id: value,
                  conn_config: {
                    ...formValues.conn_config,
                    conn_request_payload: {
                      ...formValues?.conn_config?.conn_request_payload,
                      title: value,
                      description: value,
                      deveui: value,
                    },
                  },
                });
              } else {
                setFormValues({ ...formValues, [field]: value });
              }
            }}
          />

          {formValues.device_config && (
            <DeviceTagManager
              tags={formValues.device_config.tags}
              onTagRenderingError={() => console.log("tag rendering error")}
              onTagUpdate={(updatedTags: any) => {
                setFormValues({
                  ...formValues,
                  device_config: {
                    ...formValues.device_config,
                    tags: updatedTags,
                  },
                });
              }}
            />
          )}
        </Grid>
        {formValues.device_config?.properties && (
          <DeviceProperties
            properties={formValues.device_config.properties}
            onPropertyChange={(propertyName: string, value: any) => {
              setFormValues({
                ...formValues,
                device_config: {
                  ...formValues.device_config,
                  properties: {
                    ...formValues.device_config.properties,
                    [propertyName]: value,
                  },
                },
              });
            }}
          />
        )}
        {formValues.device_config && (
          <ConnectionConfiguration
            deviceId={formValues.device_id}
            connectionConfig={formValues.conn_config || {}}
            onFieldChange={(field: string, value: string) => {
              setFormValues({
                ...formValues,
                [field]: value,
              });
            }}
          />
        )}

        <Submit
          application={formValues.application_id}
          onDone={gotoDeviceManagement}
          formValues={formValues}
        />
      </div>
    </InputContextProvider>
  );
};

export default AddDevice;
